import {API_START_DELIVERY, API_PAY_TERMINAL, API_PAY_CASH, API_FINISH_DELIVERY, API_UPDATE_LOCATION, API_NOT_PAYED} from '../../src/config/config';

function validateDelivery(token, apiURL) {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    // myHeaders.append("Cookie", "PHPSESSID=5j2c70gf3tg6rebcltjpm0v0sh");
    
    const requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    return fetch(apiURL, requestOptions)
           .then(response => response.text());
}

export function startDelivery(token, id) {

    let apiURL =  API_START_DELIVERY.replace("{{orderId}}", id);
    return fetch(apiURL, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    });
}

export async function finishPaidDelivery(token, id) {
    const apiURL =  API_FINISH_DELIVERY.replace("{{orderId}}", id);
    return validateDelivery(token, apiURL);
}

export async function finishDeliveryWithCash(token, id) {
    const apiURL =  API_PAY_CASH.replace("{{orderId}}", id);
    return validateDelivery(token, apiURL);
}

export async function finishDeliveryWithTerminal(token, id) {
    const apiURL =  API_PAY_TERMINAL.replace("{{orderId}}", id);
    return validateDelivery(token, apiURL);
}

export async function finishDeliveryNotPayed(token, id) {
    let apiURL =  API_NOT_PAYED.replace("{{orderId}}", id);
    return validateDelivery(token, apiURL);
}

export function updateCurrentLocation(token, latitude, longitude) {

    return fetch(API_UPDATE_LOCATION, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
            latitude: latitude,
            longitude: longitude
        })
    });
}