import {API_GET_STOCKS, API_PATCH_STOCK} from '../../src/config/config';


export function getStocks(token) {

    return fetch(API_GET_STOCKS, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + token
        },
    });

}

export function patchStock(id, token) {

    return fetch(API_PATCH_STOCK.replace("{{stockId}}", id), {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + token
        },
    });

}