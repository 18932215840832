import {StatAPI} from '../api';
import {ApplicationManager} from '../managers';

/*
 * action types
 */
export const SET_NEED_REFRESH = 'SET_NEED_REFRESH';

export const FETCHING_STATS_DATA_PENDING = 'FETCHING_STATS_DATA_PENDING';
export const FETCHING_STATS_DATA_SUCCESS = 'FETCHING_STATS_DATA_SUCCESS';
export const FETCHING_STATS_DATA_FAILURE = 'FETCHING_STATS_DATA_FAILURE';

/*
 * action creators
 */
export function setNeedRefresh() {
    return {
        type: SET_NEED_REFRESH
    }
}

export function getDataPending() {
    return {
        type: FETCHING_STATS_DATA_PENDING
    }
}

export function getDataSuccess(data) {
    return {
        type: FETCHING_STATS_DATA_SUCCESS,
        list: data
    }
}

export function getDataFailure(error) {
    return {
        type: FETCHING_STATS_DATA_FAILURE,
        error
    }
}

export function fetchStats() {
    return (dispatch, getState) => {
        const {user} = getState();
        let apiResponse;

        dispatch(getDataPending());

        return StatAPI.getStats(user.token)
            .then(response => {
                apiResponse = response;
                return response.json();
            })
            .then(responseData => {
                if (apiResponse.status >= 200 && apiResponse.status < 300) {
                    dispatch(getDataSuccess(responseData));
                }
                else {
                    dispatch(getDataFailure(responseData.message));
                    ApplicationManager.handleError(dispatch, responseData.message);
                }
            })
    }
}
