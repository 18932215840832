import {
    SET_ORDER_DISPLAY,
    SET_NEED_REFRESH,
    FETCHING_ORDER_DATA_PENDING,
    FETCHING_ORDER_DATA_SUCCESS,
    FETCHING_ORDER_DATA_FAILURE,
    DISPLAY
} from '../actions/Order';

const initialState = {
    list: {
        pending: null,
        delivered: null,
    },
    display: DISPLAY.PENDING,
    loading: false,
    needRefresh: false,
    error: false
};

export default function order(state = initialState, action) {
    switch (action.type) {

        case SET_ORDER_DISPLAY:
            return state = updateObject(state, {
                display: action.display,
            });

        case SET_NEED_REFRESH:
            return state = updateObject(state, {
                needRefresh: true
            });

        case FETCHING_ORDER_DATA_PENDING:
            return state = updateObject(state, {
                loading: true,
                error: false,
                needRefresh: false
            });

        case FETCHING_ORDER_DATA_SUCCESS:
            return state = updateObject(state, {
                list: {
                    ...state.list,
                    [action.key]: action.list
                },
                loading: false,
                error: false,
            });

        case FETCHING_ORDER_DATA_FAILURE:
            return state = updateObject(state, {
                loading: false,
                error: true,

            });

        default:
            return state;
    }
};

function updateObject(myObj, value) {
    return Object.assign({}, myObj, value);
}
