// @flow
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  modalView: {
    marginTop: "70%",
    paddingTop: 50,
    margin: 20,
    backgroundColor: "white",
    borderRadius: 5,
    paddingHorizontal: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.5,
    elevation: 5,
    backgroundColor: "#fefefefe"
  },
  openButton: {
    backgroundColor: "#ff003c",
    borderRadius: 2,
    padding: 5,
  },
  modalButton: {
    margin: 10,
  },
  textStyle: {
    color: "white",
    textAlign: "center"
  },
  modalText: {
    marginBottom: 25,
    fontSize: 25,
    fontWeight: "bold",
    textAlign: "center"
  }
});
