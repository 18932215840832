import {API_GET_STATS} from '../../src/config/config';


export function getStats(token) {

    return fetch(API_GET_STATS, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + token
        },
    });

}
