// @flow
import {combineReducers} from 'redux';

import user from './user';
import order from './order';
import delivery from './delivery';
import stock from './stock';
import stat from './stat';
// import rehydrated from './rehydrated';

const appReducer = combineReducers({
    user,
    order,
    delivery,
    stock,
    stat,
    // rehydrated,
});

const rootReducer = (state, action) => {
    // Handle a root reducer for global actions like user logout
    if (action.type === 'ERROR') {
        state = undefined;
    }

    return appReducer(state, action)
};

export default rootReducer;
