/*
* @flow
*
* A number button matrix
*/
import {View, TouchableOpacity, StyleSheet, Linking} from 'react-native';

import {connect} from 'react-redux';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import {setOrderDisplay, DISPLAY} from '../../../../core/actions/Order';

import React from 'react';

const handleButton = (props) => {
    props.setOrderDisplay(props.order.display === DISPLAY.PENDING ? DISPLAY.DELIVERED : DISPLAY.PENDING);
};

let styles = StyleSheet.create({
    view: {
        paddingLeft: 10,
        paddingRight: 15,
    },
    icon: {}
});

const HistoryButton = (props) => {
    // let display = props.order.display;
    let display = DISPLAY.PENDING;
    return (
        <View style={styles.view}>
            <TouchableOpacity onPress={() => {
                handleButton(props)
            }}>

                {display === DISPLAY.PENDING && <MaterialCommunityIcons
                    name="history"
                    size={24}
                    color="white"
                    style={styles.icon}/>}

                {display === DISPLAY.DELIVERED && <MaterialCommunityIcons
                    name="clock-fast"
                    size={24}
                    color="white"
                    style={styles.icon}/>}

            </TouchableOpacity>
        </View>
    );
};

function mapStateToProps(state) {
    return {
        order: state.order,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setOrderDisplay: (display) => dispatch(setOrderDisplay(display)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HistoryButton);
