import React from 'react';
import {Image, Platform, Text, TextInput, ImageBackground, View, ScrollView} from 'react-native';
import {Button} from 'react-native-elements';
import {connect} from 'react-redux';
import {login} from '../../../core/actions/User';
import Loading from '../../components/Loading';
import {NavigationActions, StackActions} from 'react-navigation';
import {ApplicationManager} from '../../../core/managers';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

// import I18n from '../../i18n/i18n';

import styles from './styles';

class Authentication extends React.Component {

    // componentWillReceiveProps(nextProps) {
    // }

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            email: null,
            password: null,
            loadingLooged: true
        };

    }

    componentDidMount() {
        ApplicationManager.getUserData().then(user => {
            if (user !== null) {
                this.redirectToIndex();
            } else {
                this.setState({
                    loadingLooged: false
                });
            }
        })
    };

    /* ======---------------------------
               Actions
   --------------------------------------------===== */

    login() {
        let {email, password} = this.state;

        this.setState({loading: true});

        this.props.login(email, password)
            .then(() => {
                this.setState({});

                if (this.props.user.logged) {
                    this.finalizeAuthenticationSuccess();
                    this.redirectToIndex();
                }


                //TODO supprimer le mdp, clearInput()
            });
    };

    finalizeAuthenticationSuccess() {

        //TODO we're not saving user data
        ApplicationManager.saveUserData(this.props.user).then();

    }

    redirectToIndex() {
        const resetAction = StackActions.reset({
            index: 0,
            actions: [
                NavigationActions.navigate({routeName: 'Index'}),
            ]
        });
        this.props.navigation.dispatch(resetAction);
    }


    /* ======---------------------------
               Renders
   --------------------------------------------===== */
    renderLoginView() {
        const error = this.props.user.error;
        const errors = {
            DEFAULT_ERROR: "Un problème est survenu lors de votre commande. Si le problème persiste merci de nous contacter.",
            INVALID_TOKEN: "Votre session n'est plus valide. Vous devez vous identifier à nouveau.",
            EXPIRED_TOKEN: "Votre session n'est plus valide. Vous devez vous identifier à nouveau.",
            MISSING_TOKEN: "Votre session n'est plus valide. Vous devez vous identifier à nouveau.",
            BAD_CREDENTIAL: "Votre identifiant n’est pas valide."
        };
        
        const translatedError = error ? errors[error] : errors.DEFAULT_ERROR;

        return (
            <KeyboardAwareScrollView>

                {this.state.loadingLooged && 
                    <Loading/>
                ||
                    <View style={styles.body}>

                        <Image
                            style={styles.logo}
                            source={require('../../resources/img/logo.png')}
                        />

                        {error && <Text style={styles.error}>{translatedError}</Text>}

                        <Text style={styles.label}>E-mail *</Text>
                        <TextInput
                            style={styles.input}
                            placeholder="E-mail"
                            keyboardType="email-address"
                            autoCapitalize="none"
                            spellCheck={false}
                            autoComplete={false}
                            autoCorrect={false}
                            underlineColorAndroid="transparent"
                            onChangeText={(text) => this.setState({email: text})}
                            returnKeyType="next"
                            onSubmitEditing={(event) => {
                                this.refs.PasswordInput.focus();
                            }}
                        />

                        <Text style={styles.label}>Mot de passe *</Text>
                        <TextInput
                            ref="PasswordInput"
                            placeholder="Mot de passe"
                            style={styles.input}
                            autoCapitalize="none"
                            secureTextEntry={true}
                            spellCheck={false}
                            autoComplete={false}
                            autoCorrect={false}
                            underlineColorAndroid="transparent"
                            onSubmitEditing={(event) => {
                                this.login()
                            }}
                            returnKeyType="go"
                            onChangeText={(text) => this.setState({password: text})}
                        />

                        <View style={styles.paddingX}>
                            <Button
                                buttonStyle={styles.buttonStylePrimary}
                                title="Me connecter"
                                onPress={() => this.login()}
                            />
                        </View>

                        <View
                            style={{height: 60}}
                        />

                    </View>

                }

            </KeyboardAwareScrollView>
        )
    }


    render() {

        const loading = this.props.user.loading;

        return (
            <ImageBackground
                style={styles.container}
                source={require('../../resources/img/background-pattern-reverse.jpg')}>

                {this.renderLoginView()}

                {loading && <Loading/>}
            </ImageBackground>

        )
    }
}


function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        login: (email, password) => dispatch(login(email, password))
    }
}

/* Second method
* const mapDispatchToProps = {
        addToCart
};
* */

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Authentication);