import {API_GET_PENDING_ORDERS, API_GET_DELIVERED_ORDERS} from '../../src/config/config';


export function getPendingOrders(token) {

    return fetch(API_GET_PENDING_ORDERS, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + token
        },
    });

}

export function getDeliveredOrders(token) {

    return fetch(API_GET_DELIVERED_ORDERS, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + token
        },
    });

}