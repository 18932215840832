import React from 'react';
import { FlatList, Text, View, ScrollView } from 'react-native';

import styles from './styles';

class StatList extends React.PureComponent {
    state = {};

    _keyExtractor = (item, index) => `list-item-${item.id}-${index}`;

    _onRefresh = () => {
        this.props.onRefresh();
    };

    _renderDeliveryManItems = ({item}) => (
        <DeliveryManStatItem
            id={item.id}
            stat={item}
        />
    );

    _renderAdminItems = ({item}) => (
        <ScrollView>
            <Text style={styles.title}>Chiffres d'affaires</Text>
            {item.global.cash.map(card => (
                <CashStatItem
                    key={card.id}
                    id={card.id}
                    stat={card}
                />
            ))}

            <Text style={styles.title}>Total cash attendu</Text>
            {item.global.toRender.map(card => (
                <ToRenderStatItem
                    id={card.id}
                    stat={card}
                />
            ))}

            <Text style={styles.title}>Livraisons en retard</Text>
            {item.global.late.map(card => (
                <LateStatItem
                    id={card.id}
                    stat={card}
                />
            ))}

            <Text style={styles.title}>Classements des livreurs</Text>
            {item.deliveryMen.map(card => (
                <DeliveryManStatItem
                    id={card.id}
                    stat={card}
                />
            ))}
        </ScrollView>
    );

    render() {
        const {data, isAdmin} = this.props;

        return <FlatList
            refreshing={this.props.loading}
            onRefresh={this._onRefresh}
            contentContainerStyle={{flex: 1}}
            data={data}
            extraData={this.state}
            keyExtractor={this._keyExtractor}
            renderItem={isAdmin ? this._renderAdminItems : this._renderDeliveryManItems}
        />;
    }
}

class CashStatItem extends React.PureComponent {
    render() {
        const { stat } = this.props;

        return (
            <View style={styles.item}>
                <View style={styles.itemContent}>
                    <View style={styles.itemCenter}>
                        <Text style={styles.itemTitle}>
                            <Text style={styles.colorPrimary}>{stat.name}</Text>
                        </Text>

                        <Text style={styles.itemData}>
                            <Text style={styles.italic}>{stat.complementaryName}</Text>
                        </Text>
                    </View>
                    <View style={styles.itemRight}>
                        <View style={styles.itemRightTop}>
                            <Text style={styles.bold}>
                                {(stat.totalAmount / 100).toFixed(2)} €
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.itemFooter}>
                    <View style={styles.itemCenter}>
                        <Text style={styles.itemData}>
                            <Text style={styles.colorPrimary}>Panier moyen : </Text>
                            <Text style={styles.semiBold}>{(stat.averageAmount / 100).toFixed(2)} €</Text>
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.itemData}>
                            <Text style={styles.colorPrimary}>Nombre de livraisons : </Text>
                            <Text style={styles.semiBold}>{stat.nbDelivery}</Text>
                        </Text>
                    </View>
                </View>
            </View>
        )
    }
}

class LateStatItem extends React.PureComponent {
    render() {
        const { stat } = this.props;

        return (
            <View style={[styles.item, styles.itemStarted]}>
                <View style={styles.itemFooter}>
                    <View style={styles.itemCenter}>
                        <Text style={styles.itemTitle}>
                            <Text style={styles.colorPrimary}>{stat.name}</Text>
                        </Text>
                    </View>
                    <View style={styles.itemRight}>
                        <View style={styles.itemRightTop}>
                            <Text style={styles.semiBold}>{stat.nbDelivery}</Text>
                        </View>
                    </View>
                </View>
            </View>
        )
    }
}

class ToRenderStatItem extends React.PureComponent {
    render() {
        const { stat } = this.props;

        return (
            <View style={[styles.item, styles.itemStarted]}>
                <View style={styles.itemFooter}>
                    <View style={styles.itemCenter}>
                        <Text style={styles.itemTitle}>
                            <Text style={styles.colorPrimary}>{stat.name}</Text>
                        </Text>
                    </View>
                    <View style={styles.itemRight}>
                        <View style={styles.itemRightTop}>
                            <Text style={styles.semiBold}>{(stat.amount / 100).toFixed(2)} €</Text>
                        </View>
                    </View>
                </View>
            </View>
        )
    }
}

class DeliveryManStatItem extends React.PureComponent {
    render() {
        const { stat } = this.props;

        return (
            <View style={styles.item}>
                <View style={styles.itemContent}>
                    <View style={styles.itemCenter}>
                        <Text style={styles.itemTitle}>
                            <Text style={styles.colorPrimary}>{stat.name}</Text>
                        </Text>
                        
                        <Text style={styles.itemData}>
                            <Text style={styles.colorPrimary}>Nombre de retards : </Text>
                            <Text style={styles.semiBold}>{stat.nbLate}</Text>
                        </Text>
                        
                        <Text style={styles.itemData}>
                            <Text style={styles.colorPrimary}>Ventes sur place : </Text>
                            <Text style={styles.semiBold}>{stat.nbSales}</Text>
                        </Text>

                        <Text style={styles.itemData}>
                            <Text style={styles.italic}>{stat.complementaryName}</Text>
                        </Text>
                    </View>
                    <View style={styles.itemRight}>
                        <View style={styles.itemRightTop}>
                            <Text style={styles.bold}>
                                {(stat.caAmount / 100).toFixed(2)} €
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.itemFooter}>
                    <View style={styles.itemCenter}>
                        <Text style={styles.itemData}>
                            <Text style={styles.colorPrimary}>Argent dû : </Text>
                            <Text style={styles.semiBold}>{(stat.cashAmount / 100).toFixed(2)} €</Text>
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.itemData}>
                            <Text style={styles.colorPrimary}>Nombre de livraisons : </Text>
                            <Text style={styles.semiBold}>{stat.nbCart}</Text>
                        </Text>
                    </View>
                </View>
            </View>
        )
    }
}

export default StatList;