// @flow
import { Dimensions, StyleSheet } from 'react-native';

let {height, width} = Dimensions.get('window');

export default StyleSheet.create({
    container: {
        flex: 1,
        // remove width and height to override fixed static size
        width: null,
        height: null,
    },
    item: {
        backgroundColor: 'white',
        marginTop: 7,
        marginBottom: 5,
        marginLeft: 7,
        marginRight: 7,
        borderColor: '#d6d7da',
        padding: 15,
        paddingBottom: 10,
        paddingTop: 8,
        flexDirection: 'column',
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,
    },
    itemStarted: {

    },
    itemContent: {
        flexDirection: 'row',
    },
    itemFooter: {

    },
    itemLeft: {
        flex: -1,
        alignSelf: 'center'
    },
    itemCenter: {
        flex: 1,
        marginLeft: 16,
        alignSelf: 'center',
    },
    itemRight: {
        flex: -1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    itemRightTop: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    itemRightBottom: {
        alignSelf: 'flex-end',
        marginTop: 6
    },
    itemTitle: {
        textAlign: 'left',
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 4,
        color: '#0f0f0f'
    },
    itemData: {
        color: '#0f0f0f'
    },
    itemIndex: {
        color: '#32a583',
        fontWeight: 'bold'
    },
    itemIndexHistory: {
        color: '#a63a32',
        fontWeight: 'bold'
    },
    colorPrimary: {
        color: '#67256a',
    },
    colorSecondary: {
        color: '#fda127',
    },
    colorTertiary: {
        color: '#32a583',
    },
});
