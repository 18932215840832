let packageInformation = require('../../package.json');

let env = 0;
if(packageInformation.environment === "pre-production") {
    env = 1;
    // This is for debugging
    GLOBAL.XMLHttpRequest = GLOBAL.originalXMLHttpRequest || GLOBAL.XMLHttpRequest;
}
else if(packageInformation.environment === "production") {
    env = 2;
}
else {
    GLOBAL.XMLHttpRequest = GLOBAL.originalXMLHttpRequest || GLOBAL.XMLHttpRequest;
}

export const APP_URLS = [
    "http://xoxotime:8888/",
    "https://mariamskitchen.snowpact.com/",
    "https://backend.mariamskitchen.fr/",
];


export const API_LOGIN                      = APP_URLS[env] + 'api/login_check';

export const API_GET_PENDING_ORDERS         = APP_URLS[env] + 'api/carts/delivery_man/current';
export const API_GET_DELIVERED_ORDERS       = APP_URLS[env] + 'api/carts/delivery_man/current/done';

export const API_GET_STATS                  = APP_URLS[env] + 'api/stats';

export const API_GET_STOCKS                 = APP_URLS[env] + 'api/stock';
export const API_PATCH_STOCK                = APP_URLS[env] + 'api/stock/{{stockId}}/remove';

export const API_UPDATE_LOCATION            = APP_URLS[env] + 'api/delivery_men/location';

export const API_FINISH_DELIVERY            = APP_URLS[env] + 'api/carts/{{orderId}}/deliver';
export const API_PAY_TERMINAL               = APP_URLS[env] + 'api/carts/{{orderId}}/payed/payment_terminal';
export const API_PAY_CASH                   = APP_URLS[env] + 'api/carts/{{orderId}}/payed/cash';
export const API_NOT_PAYED                  = APP_URLS[env] + 'api/carts/{{orderId}}/cancel';

export const API_START_DELIVERY             = APP_URLS[env] + 'api/carts/{{orderId}}/start-delivery';


export const INVALID_TOKEN = "INVALID_TOKEN";
export const MISSING_TOKEN = "MISSING_TOKEN";
export const EXPIRED_TOKEN = "EXPIRED_TOKEN";

// TODO proper way, this is for debugging
