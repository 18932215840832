import React from 'react';
import {View} from 'react-native';
import Entypo from 'react-native-vector-icons/Entypo';
import Ionicons from 'react-native-vector-icons/Ionicons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';
import { createMaterialBottomTabNavigator } from 'react-navigation-material-bottom-tabs';

import Order from '../screens/Order';
import Delivery from '../screens/Delivery';
import Authentication from '../screens/Authentication';
import Error from '../screens/Error';
import Success from '../screens/Success';
import Stock from '../screens/Stock';
import Stat from '../screens/Stat';

import {PhoneButton, HistoryButton, SignOutButton} from '../components/Menu';

const stackDeliveryConfigs = {
  initialRouteName: 'Index',
  navigationOptions: () => ({
    title: "Commandes",
    headerTintColor: "white",
    headerBackTitle: "Retour",
    headerStyle: {
      backgroundColor: "#67256a"
    }
  })
};
const routeOrderConfigs = {
  Index: {
    screen: Order,
    navigationOptions: ({navigation}) => ({
      title: 'Commandes',
      headerStyle: {
        backgroundColor: '#fda127',
      },
      headerTintColor: '#fff',
      headerLeft: () => <View style={{flexDirection: 'row'}}>
                          <SignOutButton navigation={navigation}/>
                        </View>,
      headerRight: () => <View style={{flexDirection: 'row'}}>
                          <HistoryButton navigation={navigation}/>
                        </View>,
    }),
  },
  Delivery: {
    screen: Delivery,
    navigationOptions: ({navigation}) => ({
      headerStyle: {
        backgroundColor: '#fda127',
      },
      headerTintColor: '#fff',
      title: 'Livraison',
      headerRight: () => <View style={{flexDirection: 'row'}}>
                          <PhoneButton navigation={navigation}/>
                        </View>,
    }),
  }
};
const OrderStack = createStackNavigator(routeOrderConfigs, stackDeliveryConfigs);

const StocksStack = createStackNavigator({
  Index: {
    screen: Stock,
    navigationOptions: ({navigation}) => ({
      headerStyle: {
        backgroundColor: '#fda127',
      },
      headerTintColor: '#fff',
      title: 'Stocks',
      headerLeft: () => <View style={{flexDirection: 'row'}}>
                          <SignOutButton navigation={navigation}/>
                        </View>,
    })
  }
});

const StatsStack = createStackNavigator({
  Index: {
    screen: Stat,
    navigationOptions: ({navigation}) => ({
      headerStyle: {
        backgroundColor: '#fda127',
      },
      headerTintColor: '#fff',
      title: 'Statistiques',
      headerLeft: () => <View style={{flexDirection: 'row'}}>
                          <SignOutButton navigation={navigation}/>
                        </View>,
    })
  }
});

const TabNavigatorCustomer = createMaterialBottomTabNavigator({
  Order: {
    screen: OrderStack,
    navigationOptions: () => ({
      title: 'Commandes',
      tabBarIcon: ({ tintColor }) => <Entypo size={26} name="location-pin" color={tintColor} />
    }),
  },
  Stocks : {
    screen: StocksStack,
    navigationOptions: () => ({
      title: 'Stocks',
      tabBarIcon: ({ tintColor }) => <MaterialCommunityIcons size={26} name="format-list-numbered-rtl" color={tintColor} />
    }),
  },
  Stats : {
    screen: StatsStack,
    navigationOptions: () => ({
      title: 'Statistiques',
      tabBarIcon: ({ tintColor }) => <Ionicons size={26} name="ios-stats" color={tintColor} />
    }),
  }
}, {
  initialRouteName: 'Order',
  activeColor: '#f0edf6',
  inactiveColor: '#aaaaaa',
  barStyle: { backgroundColor: '#30122f' },
});

const TabNavigatorAdmin = createMaterialBottomTabNavigator({
  Stocks : {
    screen: StocksStack,
    navigationOptions: () => ({
      title: 'Stocks',
      tabBarIcon: ({ tintColor }) => <MaterialCommunityIcons size={26} name="format-list-numbered-rtl" color={tintColor} />
    }),
  },
  Stats : {
    screen: StatsStack,
    navigationOptions: () => ({
      title: 'Statistiques',
      tabBarIcon: ({ tintColor }) => <Ionicons size={26} name="ios-stats" color={tintColor} />
    }),
  }
}, {
  initialRouteName: 'Stocks',
  activeColor: '#f0edf6',
  inactiveColor: '#aaaaaa',
  barStyle: { backgroundColor: '#30122f' },
});

const stackNavigatorConfigs = {
  initialRouteName: 'Authentication',
  navigationOptions: (toto) => {
    console.log('toto', toto)
    return {
      title: "XOXO Time Livreur",
      headerTintColor: "white",
      headerBackTitle: "Retour",
      headerStyle: {
        backgroundColor: "#67256a"
      }
    }
  }
};
const routeConfigs = {
  Index: {
    screen: TabNavigatorCustomer,
    navigationOptions: () => ({
      headerShown: false,
    })
  },
  Authentication: {
      screen: Authentication,
      navigationOptions: () => ({
        headerStyle: {
          backgroundColor: '#fda127',
        },
        headerTintColor: '#fff',
        title: "XOXO Time Livreur"
      }),
  },
  Error: {
      screen: Error,
      navigationOptions: () => ({
        headerStyle: {
          backgroundColor: '#fda127',
        },
        headerTintColor: '#fff',
        title: 'Erreur'
      }),
  },
  Success: {
      screen: Success,
      navigationOptions: () => ({
        headerStyle: {
          backgroundColor: '#fda127',
        },
        headerTintColor: '#fff',
        title: 'Succès'
      }),
  }
};

export const AppNavigatorWrapper = createAppContainer(
  createStackNavigator(
    routeConfigs,
    stackNavigatorConfigs
  )
);

export const AppAdminNavigatorWrapper = createAppContainer(
  createStackNavigator(
    {
      ...routeConfigs,
      Index: {
        ...routeConfigs.Index,
        screen: TabNavigatorAdmin,
      }
    },
    stackNavigatorConfigs
  )
);
