/*
* @flow
*
* A number button matrix
*/
import { View, TouchableOpacity, StyleSheet, Linking } from 'react-native';

import {connect} from 'react-redux';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import React from 'react';

const handleButton = (props) => {
    props.navigation.goBack();
};

let styles = StyleSheet.create({
    view: {
        paddingLeft: 10,
        paddingRight: 15,
    },
    icon: {
    }
});

const CancelButton = (props) => {
    return (
        <View style={styles.view}>
            <TouchableOpacity onPress={() => { handleButton(props) }}>
                <MaterialCommunityIcons
                    name="cancel"
                    size={24}
                    color="white"
                    style={styles.icon} />

            </TouchableOpacity>
        </View>
    );
};

function mapStateToProps (state) {
    return {
    }
}

function mapDispatchToProps (dispatch) {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CancelButton);
