import React from 'react';
import {FlatList, View, TouchableOpacity, Text} from 'react-native';
import {DISPLAY} from '../../../../core/actions/Order';

// import I18n from '../../../i18n/i18n';
import styles from './styles';

const DATE = {
    today: 'Aujourd\'hui',
    tomorrow: 'Demain',
    day0: 'Dimanche',
    day1: 'Lundi',
    day2: 'Mardi',
    day3: 'Mercredi',
    day4: 'Jeudi',
    day5: 'Vendredi',
    day6: 'Samedi',
    month0: 'Janvier',
    month1: 'Février',
    month2: 'Mars',
    month3: 'Avril',
    month4: 'Mai',
    month5: 'Juin',
    month6: 'Juillet',
    month7: 'Août',
    month8: 'Septembre',
    month9: 'Octobre',
    month10: 'Novembre',
    month11: 'Décembre',
}

class OrderItem extends React.PureComponent {

    formatNumber(number) {
        return ('0' + number).slice(-2);
    };

    formatHours(date) {
        if (date === null) {
            return "";
        }

        let dateObject = new Date(date);

        return ('0' + dateObject.getHours()).slice(-2) + ':' + ('0' + dateObject.getMinutes()).slice(-2);
    };

    formatDate(date) {
        if (date === null) {
            return "";
        }
        let dateObject = new Date(date);

        return (DATE['day' + dateObject.getDay()]) + ' ' + dateObject.getDate() + ' ' + (DATE['month' + dateObject.getMonth()])
        + ', ' + ('0' + dateObject.getHours()).slice(-2) + ':' + ('0' + dateObject.getMinutes()).slice(-2);
    };


    _onPress = (order) => {
        this.props.onPressItem(order);
    };

    render() {
        let order = this.props.order;

        return (
            <TouchableOpacity onPress={() => this._onPress(order)}>
                <View style={[styles.item, order.started ? styles.itemStarted : null]}>
                    <View style={styles.itemContent}>
                        <View style={styles.itemLeft}>
                            {this.props.display === DISPLAY.PENDING &&
                            <Text style={styles.itemIndex}>{this.formatNumber(parseInt(order.routineIndex) + 1)}</Text>
                            }
                            {this.props.display === DISPLAY.DELIVERED &&
                            <Text style={styles.itemIndexHistory}>{this.formatNumber(parseInt(order.routineIndex) + 1)}</Text>
                            }
                        </View>
                        <View style={styles.itemCenter}>
                            <Text style={styles.itemTitle}>
                                <Text>Commande n° </Text>
                                <Text style={styles.colorPrimary}>{order.id}</Text>
                            </Text>

                            {this.props.display === DISPLAY.PENDING &&
                            <Text style={styles.itemData}>
                                <Text>Prévu à : </Text>
                                <Text style={styles.colorPrimary}>{this.formatHours(order.wishDeliveryDate)}</Text>
                            </Text>
                            }

                            {this.props.display === DISPLAY.DELIVERED &&
                            <Text style={styles.itemData}>
                                <Text>Livré à : </Text>
                                <Text style={styles.colorPrimary}>{this.formatHours(order.deliveryDate)}</Text>
                            </Text>
                            }

                            <Text style={styles.itemData}>
                                <Text>Client : </Text>
                                <Text style={styles.italic}>{order.customer.formattedName}</Text>
                            </Text>

                            <Text style={styles.itemData}>
                                <Text>Adresse : </Text>
                                <Text style={styles.italic}>{order.address}</Text>
                            </Text>

                        </View>
                        <View style={styles.itemRight}>
                            <View style={styles.itemRightTop}>

                            </View>
                            <View style={styles.itemRightBottom}>

                            </View>
                        </View>
                    </View>
                </View>
            </TouchableOpacity>)
    }
}

class OrderList extends React.PureComponent {
    state = {};

    _keyExtractor = (item, index) => item.id;

    _onPressItem = (order) => {
        this.props.onPress(order);
    };

    _onRefresh = () => {
        this.props.onRefresh();
    };


    _renderItem = ({item}) => (
        <OrderItem
            id={item.id}
            order={item}
            onPressItem={this._onPressItem}
            display={this.props.display}
        /> );

    extractImages() {

    }

    render() {

        return (
            <FlatList
                refreshing={this.props.loading}
                onRefresh={this._onRefresh}
                contentContainerStyle={{flex: 0}}
                data={this.props.data}
                extraData={this.state}
                keyExtractor={this._keyExtractor}
                renderItem={this._renderItem}/>
        );

    }
}

export default OrderList;