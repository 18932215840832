import React from 'react';
import {ImageBackground, View, Text} from 'react-native';

import StockList from '../../components/List/StockList';
import Loading from '../../components/Loading';

import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import {login} from '../../../core/actions/User';
import {fetchStocks, patchStockQuantity} from '../../../core/actions/Stock';
import {NavigationActions, StackActions} from 'react-navigation';

import {connect} from 'react-redux';

import styles from './styles';

import {ApplicationManager} from '../../../core/managers';


class Stock extends React.Component {

    constructor(props) {
        super(props);

        this.props.fetchStocks();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.user.logged) {

            const resetAction = StackActions.reset({
                index: 0,
                actions: [
                    NavigationActions.navigate({routeName: 'Authentication' })
                ]
            });
            this.props.navigation.dispatch(resetAction);

            ApplicationManager.saveUserData(null).done();
        }

        if (nextProps.stock.needRefresh) {
            this.props.fetchStocks();
        }
    }

    /* ======---------------------------
              Actions
    --------------------------------------------===== */
    onRefresh = () => {
        this.props.fetchStocks();
    };

    removeOneStockQuantity = id => {
        this.props.patchStockQuantity(id);
    };

    /* ======---------------------------
               Renders
   --------------------------------------------===== */

    renderEmptyView() {
        return (
            <View style={styles.emptyView}>
                <MaterialCommunityIcons
                    name="cart-outline"
                    size={100}
                    color="#848484"
                    style={styles.emptyIcon}/>

                <Text style={styles.emptyText}>Aucun stock n'est actuellement disponible...</Text>
            </View>
        );
    }

    render() {
        const loading = this.props.stock.loading;
        const stocks = this.props.stock.list;
        const { isAdmin } = this.props.user;

        return (
            <ImageBackground
                style={styles.container}
                source={require('../../resources/img/background-pattern.jpg')}>

                <StockList
                    removeStockBtn={isAdmin}
                    loading={loading}
                    onRefresh={this.onRefresh}
                    data={stocks}
                    onPress={stock => this.removeOneStockQuantity(stock.id)}
                />

                {loading && <Loading/>}

            </ImageBackground>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        stock: state.stock,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        login: (email, password) => dispatch(login(email, password)),
        fetchStocks: () => dispatch(fetchStocks()),
        patchStockQuantity: (id) => dispatch(patchStockQuantity(id)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Stock);
