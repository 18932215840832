import React from 'react';
import {Linking, Platform, ImageBackground, View, ScrollView, Text} from 'react-native';
import Button from 'react-native-button';
import Loading from '../../components/Loading';
import {NavigationActions} from 'react-navigation';

// import I18n from '../../i18n/i18n';

import {setNeedRefresh, DISPLAY} from '../../../core/actions/Order';
import {
    startDelivery,
    finishDeliveryWithCash,
    finishDeliveryWithTerminal,
    finishDeliveryNotPayed,
    finishPaidDelivery,
    updateCurrentLocation
} from '../../../core/actions/Delivery';

import {connect} from 'react-redux';

import styles from './styles';

let DELIVERY_STATE = {
    WAITING: 0,
    DELIVERING: 1,
    PREPARING: 2,
    FINISHING_NEED_PAYMENT: 3,
    FINISHING_PAID: 4,
    FINISHED: 10,
};

let END_TYPE = {
    ALREADY_PAID: -1,
    PAYED_WITH_CASH: 0,
    PAYED_WITH_TERMINAL: 1,
    NOT_PAYED: 2,
};

const API_STATE = {
    STATE_ASSIGNED: "STATE_ASSIGNED"
};

const API_PAYMENT = {
    PAYMENT_STATE_UNPAID: "PAYMENT_STATE_UNPAID",
};

const DATE = {
    today: 'Aujourd\'hui',
    tomorrow: 'Demain',
    day0: 'Dimanche',
    day1: 'Lundi',
    day2: 'Mardi',
    day3: 'Mercredi',
    day4: 'Jeudi',
    day5: 'Vendredi',
    day6: 'Samedi',
    month0: 'Janvier',
    month1: 'Février',
    month2: 'Mars',
    month3: 'Avril',
    month4: 'Mai',
    month5: 'Juin',
    month6: 'Juillet',
    month7: 'Août',
    month8: 'Septembre',
    month9: 'Octobre',
    month10: 'Novembre',
    month11: 'Décembre',
}

class OrderDetails extends React.Component {

    constructor(props) {
        super(props);

        let order = this.findOrder(this.props.navigation.state.params.id, this.props.navigation.state.params.category);
        if (order) {
            let delivery = DELIVERY_STATE.WAITING;
            if (order.state !== API_STATE.STATE_ASSIGNED) {
                delivery = DELIVERY_STATE.FINISHED;
            }
            else if (order.started) {
                delivery = DELIVERY_STATE.DELIVERING;
            }

            this.state = {
                deliveryState: delivery
            }
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidMount() {
    };

    findOrder(id, category) {
        let orders = category === DISPLAY.PENDING ? this.props.order.list.pending : this.props.order.list.delivered;

        for (let i = 0; i < orders.length; i++) {
            if (orders[i].id === id) {
                return orders[i];
            }
        }
        return null;
    }

    /* ======---------------------------
               Actions
   --------------------------------------------===== */
    startDelivery(order) {
        this.updateLocation(this.props);

        this.props.startDelivery(order.id).then((responseData) => {

            if (responseData.sms) {
                this.sendSms(`${order.customer.phoneNumber}`, responseData.sms);
            }
            else {
                this.sendSms(`${order.customer.phoneNumber}`);
            }
        });

        this.setState({
            deliveryState: DELIVERY_STATE.DELIVERING
        });

        this.props.setNeedRefresh();

    }

    preparingDelivery(order) {
        this.props.setNeedRefresh();

        this.setState({
            deliveryState: DELIVERY_STATE.PREPARING
        });
    }

    finishingDelivery(order) {
        if (order.paymentState === API_PAYMENT.PAYMENT_STATE_UNPAID) {
            this.setState({
                deliveryState: DELIVERY_STATE.FINISHING_NEED_PAYMENT
            });
        }
        else {
            this.setState({
                deliveryState: DELIVERY_STATE.FINISHED
            });
            this.finishDelivery(order, END_TYPE.ALREADY_PAID);
        }
    }

    finishDelivery(order, type) {
        this.updateLocation(this.props);

        switch (type) {
            case END_TYPE.ALREADY_PAID:
                this.props.finishPaidDelivery(order.id).then(() => {
                    this.redirectToSuccessPage();
                })
                break;
            case END_TYPE.PAYED_WITH_CASH:
                this.props.finishDeliveryWithCash(order.id).then(() => {
                    this.redirectToSuccessPage();
                });
                break;
            case END_TYPE.PAYED_WITH_TERMINAL:
                this.props.finishDeliveryWithTerminal(order.id).then(() => {
                    this.redirectToSuccessPage();
                });
                break;
            case END_TYPE.NOT_PAYED:
                this.props.finishDeliveryNotPayed(order.id).then(() => {
                    this.redirectToSuccessPage();
                });
                break;
        }
    }

    openMap(order, type = 'maps') {
        let mapUrl = "";
        if (type === 'maps') {
            if (Platform.OS === 'ios') {
                mapUrl = "http://maps.apple.com/?ll=" + order.position.latitude + "," + order.position.longitude;
            } else {
                mapUrl = "geo:" + order.position.latitude + "," + order.position.longitude;
                //mapUrl = "https://www.google.com/maps/search/?api=1&query=" + order.position.latitude + "," + order.position.longitude;
            }
        } else {
            mapUrl = "https://www.waze.com/ul?ll=" + order.position.latitude + "," + order.position.longitude + "&navigate=yes&zoom=17"
        }

        Linking.openURL(mapUrl);
    }

    sendSms(phoneNumber, body = null) {
        if (body === null) {
            body = "Bonjour, \nvotre livreur XOXO Time est en route pour votre livraison.";
        }

        const sms = "sms:" + phoneNumber + this.getSMSDivider() + "body=" + body;

        Linking.openURL(sms)
            .catch(
                err => console.error('An error occurred', err)
            );

    }

    getSMSDivider() {
        return Platform.OS === "ios" ? "&" : "?";
    }

    updateLocation(props) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                props.updateCurrentLocation(position.coords.latitude, position.coords.longitude);
            },
            (error) => this.setState({error: error.message}),
            {
                enableHighAccuracy: true,
                timeout: 20000,
                maximumAge: 60 * 1000
            },
        );
    }

    /* ======---------------------------
               Helpers
   --------------------------------------------===== */

    formatDate(date) {
        if (date === null) {
            return "";
        }
        let dateObject = new Date(date);

        return (DATE['day' + dateObject.getDay()]) + ' ' + dateObject.getDate() + ' ' + (DATE['month' + dateObject.getMonth()])
        + ', ' + ('0' + dateObject.getHours()).slice(-2) + ':' + ('0' + dateObject.getMinutes()).slice(-2);
    };

    redirectToSuccessPage() {
        this.setState({
            deliveryState: DELIVERY_STATE.FINISHED
        });

        this.props.setNeedRefresh();

        this.props.navigation.navigate({
            routeName: 'Success',
            params: {...{title: "Livraison effectuée", message: "Votre livraison a été éffectuée avec succès."}}
        });
    }

    /* ======---------------------------
               Renders
   --------------------------------------------===== */
    renderProduct(key, quantity, product, price, description = "") {
        let realKey = "p" + key;
        return (
            <View key={realKey} style={styles.article}>
                <View style={styles.horizontalSeparator}/>
                <View style={styles.inline}>
                    <Text style={styles.inlineLeft}>
                        <Text style={styles.quantity}>{quantity}x</Text> <Text style={styles.infoTitle}>{product}</Text>
                    </Text>
                    {price === 0 &&
                        <Text style={styles.inlineRight}>Offert</Text>
                    }
                </View>
                {description !== "" &&
                    <Text style={styles.paddingLeft}>{description}</Text>
                }
            </View>
        )
    };

    renderFormula(key, formula) {
        let realKey = "f" + key;
        let formattedCompositions = "";
        for (let i = 0; i < formula.composition.length; i++) {
            formattedCompositions += formula.composition[i].nameProduct + ", ";
        }
        let formattedDescription = "";
        for (let i = 0; i < formula.composition.length; i++) {
            formattedDescription += formula.composition[i].deliveryManDescription ? formula.composition[i].deliveryManDescription + ", " : "";
        }

        return (
            <View key={realKey} style={styles.article}>
                <View style={styles.horizontalSeparator}/>
                <Text>
                    <Text style={styles.quantity}>{formula.quantity}x</Text> <Text style={styles.infoTitle}>{formula.name}</Text>
                </Text>
                <Text>{formattedCompositions}</Text>
                <Text>{formattedDescription}</Text>
            </View>
        )
    };


    diff_minutes(dt2, dt1) {
        let diff = (dt2 - dt1) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));
    }

    render() {
        const loading = this.props.delivery.loading || this.props.order.loading;
        const order = this.findOrder(this.props.navigation.state.params.id, this.props.navigation.state.params.category);
        let orderUnpaid = true;
        if (order !== null) {
            if (order.complementaryAddress !== null && order.complementaryAddress.length <= 0) {
                order.complementaryAddress = null;
            }
            if (order.paymentState !== API_PAYMENT.PAYMENT_STATE_UNPAID) {
                orderUnpaid = false;
            }
        } 
        const orderPaymentStateMessage = orderUnpaid ? 'Non payé' : 'Payé';

        const now = (new Date()).getTime();
        const wish = (new Date(order.wishDeliveryDate)).getTime();

        return <>
            {order !== null &&
                <ImageBackground
                    style={styles.container}
                    source={require('../../resources/img/background-pattern.jpg')}>

                    <ScrollView>

                        <View style={styles.block}>
                            <Text style={styles.infoTitle}>Numéro de commande</Text>
                            <Text style={styles.infoDescription}>#{order.id}</Text>
                            <Text style={styles.infoTitle}>Client</Text>
                            <Text style={styles.infoDescription}>{order.customer.formattedName}</Text>
                            <Text style={styles.infoTitle}>Livraison prévue le</Text>
                            <Text style={styles.infoMain}>{this.formatDate(order.wishDeliveryDate)}</Text>
                            <Text style={styles.infoTitle}>Adresse</Text>
                            <Text style={styles.infoSubMain}>{order.address}</Text>
                            {order.complementaryAddress && <Text style={styles.infoDescription}>{order.complementaryAddress}</Text>}
                            <Text style={styles.infoTitle}>État du paiement</Text>
                            <Text
                                style={styles.infoSubMain, {
                                    color: orderUnpaid ? 'red' : 'green',
                                    fontSize: orderUnpaid ? 25 : 20,
                                }}
                            >
                                {orderPaymentStateMessage}
                            </Text>

                            {wish < now && 
                                <>
                                    <Text style={styles.infoTitle}>Retard</Text>
                                    <Text
                                        style={styles.infoSubMain, {
                                            color: 'red',
                                            fontSize: 25,
                                        }}
                                    >
                                        En retard de {this.diff_minutes(wish, now)} minutes, penser à s'excuser
                                    </Text>
                                </>
                            }

                            <View style={styles.space}/>
                        </View>

                        {this.state.deliveryState >= DELIVERY_STATE.PREPARING && <View style={styles.block}>
                            <Text style={styles.mainTitle}>Panier</Text>
                            {
                                Object.keys(order.formulas).map(key => this.renderFormula(key, order.formulas[key]))
                            }
                            {
                                Object.keys(order.products).map(key => this.renderProduct(
                                    key,
                                    order.products[key].quantity,
                                    order.products[key].name,
                                    order.products[key].price.amount,
                                    order.products[key].deliveryManDescription
                                ))
                            }
                            {order.cutlery > 0 && this.renderProduct("cutlery", order.cutlery, "Couverts", 0)}
                            {order.salt > 0 && this.renderProduct("salt", order.salt, "Dosettes de sel", 0)}
                        </View>}

                        <View style={styles.block}>
                            <Text style={styles.mainTitle}>Remarques</Text>
                            <Text style={styles.infoDescription}>{order.remark}</Text>
                        </View>

                        <View style={styles.block}>
                            <View style={styles.recapLine}>
                                <Text style={[styles.recapLineTitle, styles.boldColor]}>TOTAL</Text>
                                {order.total.amount !== order.final.amount &&
                                <Text style={[styles.recapLineData, styles.lineThrough]}>{(order.total.amount / 100).toFixed(2)}€</Text>
                                }
                                <Text style={[styles.recapLineData, styles.boldColor, order.paymentState === API_PAYMENT.PAYMENT_STATE_UNPAID ? null : styles.lineThrough]}>{(order.final.amount / 100).toFixed(2)}€</Text>
                            </View>
                        </View>
                        <View style={styles.margin}/>

                    </ScrollView>


                    <View style={styles.footer}>

                        {this.state.deliveryState === DELIVERY_STATE.WAITING &&
                            <Button
                                style={{fontSize: 14, color: 'white'}}
                                containerStyle={styles.primaryButton}
                                onPress={() => this.startDelivery(order)}
                            >
                                Démarrer la livraison + SMS
                            </Button>
                        }

                        {this.state.deliveryState === DELIVERY_STATE.DELIVERING &&
                            <>
                                <Button
                                    style={{fontSize: 14, color: 'white'}}
                                    containerStyle={styles.secondaryButton}
                                    onPress={() => this.openMap(order)}
                                >
                                    Maps
                                </Button>
                                <Button
                                    style={{fontSize: 14, color: 'white'}}
                                    containerStyle={styles.secondaryButton}
                                    onPress={() => this.openMap(order, 'waze')}
                                >
                                    Waze
                                </Button>
                            </>
                        }

                        {this.state.deliveryState === DELIVERY_STATE.DELIVERING &&
                            <Button
                                style={{fontSize: 14, color: 'white'}}
                                containerStyle={styles.primaryButton}
                                onPress={() => this.preparingDelivery(order)}
                            >
                                Préparation
                            </Button>
                        }

                        {this.state.deliveryState === DELIVERY_STATE.PREPARING &&
                            <Button
                                style={{fontSize: 14, color: 'white'}}
                                containerStyle={styles.primaryButton}
                                onPress={() => this.finishingDelivery(order)}
                            >
                                Livraison effectuée
                            </Button>
                        }

                        {this.state.deliveryState === DELIVERY_STATE.FINISHING_NEED_PAYMENT &&
                            <Button
                                style={{fontSize: 14, color: 'white'}}
                                containerStyle={styles.tertiaryButton}
                                onPress={() => this.finishDelivery(order, END_TYPE.PAYED_WITH_CASH)}
                            >
                                Paiement reçu
                            </Button>
                        }

                        {this.state.deliveryState === DELIVERY_STATE.FINISHED &&
                            <Button
                                style={{fontSize: 14, color: 'white'}}
                                containerStyle={styles.secondaryButton}
                                onPress={() => this.openMap(order)}
                            >
                                GPS
                            </Button>
                        }

                    </View>

                    {loading && <Loading/>}


                </ImageBackground>
            }
        </>
    }
}


function mapStateToProps(state) {
    return {
        order: state.order,
        delivery: state.delivery,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        startDelivery: (orderId) => dispatch(startDelivery(orderId)),
        finishPaidDelivery: (orderId) => dispatch(finishPaidDelivery(orderId)),
        finishDeliveryWithCash: (orderId) => dispatch(finishDeliveryWithCash(orderId)),
        finishDeliveryWithTerminal: (orderId) => dispatch(finishDeliveryWithTerminal(orderId)),
        finishDeliveryNotPayed: (orderId) => dispatch(finishDeliveryNotPayed(orderId)),
        updateCurrentLocation: (latitude, longitude) => dispatch(updateCurrentLocation(latitude, longitude)),
        setNeedRefresh: () => dispatch(setNeedRefresh()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderDetails);
