import React, { Component } from "react";
import {
  Alert,
  Modal,
  Text,
  TouchableHighlight,
  View
} from "react-native";

import styles from './styles';

class ConfirmModal extends Component {
  state = {
    modalVisible: false
  };

  setModalVisible = (visible) => {
    this.setState({ modalVisible: visible });
  }

  render() {
    const { modalVisible } = this.state;

    return (
      <View style={styles.centeredView}>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text style={styles.modalText}>{this.props.title}</Text>

              <View style={{direction: 'ltr', display: 'flex', flexDirection: 'row'}}>
                <TouchableHighlight
                  style={{ ...styles.openButton, ...styles.modalButton, backgroundColor: "#ff003c" }}
                  onPress={() => {
                    this.setModalVisible(!modalVisible);
                  }}
                >
                  <Text style={{ ...styles.textStyle, fontSize: 20 }}>Annuler</Text>
                </TouchableHighlight>


                <TouchableHighlight
                  style={{ ...styles.openButton, ...styles.modalButton, backgroundColor: "#17c700" }}
                  onPress={() => {
                    this.props.onConfirm(this.props.stock);
                    this.setModalVisible(!modalVisible);
                  }}
                >
                  <Text style={{ ...styles.textStyle, fontSize: 20 }}>Confirmer</Text>
                </TouchableHighlight>
              </View>
            </View>
          </View>
        </Modal>

        <TouchableHighlight
          style={styles.openButton}
          onPress={() => {
            this.setModalVisible(true);
          }}
        >
          <Text style={styles.textStyle}>Retirer 1</Text>
        </TouchableHighlight>
      </View>
    );
  }
}

export default ConfirmModal;
