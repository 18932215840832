import jwt_decode from 'jwt-decode';

import {UserAPI} from '../api';

// import {NavigationActions} from 'react-navigation';

/*
 * action types
 */

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';


/*
 * action creators
 */

export function logout() {
    return {
        type: LOGOUT
    }
}

export function loginPending() {
    return {
        type: LOGIN_PENDING
    }
}

export function loginSuccess(username, token, isAdmin) {
    return {
        type: LOGIN_SUCCESS,
        username,
        token,
        isAdmin
    }
}

export function loginFailure(error) {
    return {
        type: LOGIN_FAILURE,
        error
    }
}

export function login(email, password) {
    return (dispatch) => {
        let apiResponse;

        dispatch(loginPending());

        return UserAPI.login(email, password)
            .then(response => {
                apiResponse = response;
                return response.json();
            })
            .then(responseData => {
                if (responseData.token) {
                    if(responseData.role === 'ROLE_DELIVERY_MAN') {
                        const { token } = responseData;
                        const roles = jwt_decode(token).roles;
                        const isAdmin = roles.includes('ROLE_ADMIN');

                        dispatch(loginSuccess(email, token, isAdmin));
                    }
                    else {
                        dispatch(loginFailure('BAD_CREDENTIAL'));
                    }
                }
                else {
                    dispatch(loginFailure(responseData.message));
                }
            })
            .catch(error => {
                dispatch(loginFailure(error));
            });
    }
}
