// @flow
import { Dimensions, StyleSheet } from 'react-native';

let {height, width} = Dimensions.get('window');

export default StyleSheet.create({
    container: {
        flex: 1,
        // remove width and height to override fixed static size
        width: null,
        height: null,
    },
    colorPrimary: {
        color: '#67256a',
    },
    colorSecondary: {
        color: '#fda127',
    },
    colorTertiary: {
        color: '#32a583',
    },
    colorDanger: {
        color: '#a63a32',
    },
    headerPending: {
        backgroundColor: '#32a583',
        padding: 15,
        textAlign: 'center',
        color: '#fff',
        fontSize: 15
    },
    headerDelivery: {
        backgroundColor: '#a63a32',
        padding: 15,
        textAlign: 'center',
        color: '#fff',
        fontSize: 15
    },
    emptyView: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignContent: 'center',
        padding: 50
    },
    emptyText: {
        color: "#848484",
        textAlign: 'center'
    },
    emptyIcon: {
        marginTop: 20,
        alignSelf: 'center',
        transform: [
            {rotateX: '180deg'},
            {rotateY: '180deg'},
        ],
    },
});
