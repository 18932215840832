/*
* @flow
*
* A number button matrix
*/
import {View, TouchableOpacity, StyleSheet, Linking} from 'react-native';

import {connect} from 'react-redux';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import React from 'react';

const handleButton = (props) => {
    let phoneNumber = null;
    if (props.navigation.state.params.phoneNumber !== null) {
        phoneNumber = props.navigation.state.params.phoneNumber;
    }


    Linking.openURL('tel:' + phoneNumber)
        .catch(
            err => console.log('An error occurred', err)
        );

};

let styles = StyleSheet.create({
    view: {
        paddingLeft: 10,
        paddingRight: 15,
    },
    icon: {}
});

const PhoneButton = (props) => {
    return (
        <View style={styles.view}>
            <TouchableOpacity onPress={() => {
                handleButton(props)
            }}>
                <MaterialCommunityIcons
                    name="phone"
                    size={24}
                    color="white"
                    style={styles.icon}/>

            </TouchableOpacity>
        </View>
    );
};

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PhoneButton);
