import {OrderAPI} from '../api';
import {ApplicationManager} from '../managers';

/*
 * action types
 */
export const SET_ORDER_DISPLAY = 'SET_ORDER_DISPLAY';

export const SET_NEED_REFRESH = 'SET_NEED_REFRESH';

export const FETCHING_ORDER_DATA_PENDING = 'FETCHING_ORDER_DATA_PENDING';
export const FETCHING_ORDER_DATA_SUCCESS = 'FETCHING_ORDER_DATA_SUCCESS';
export const FETCHING_ORDER_DATA_FAILURE = 'FETCHING_ORDER_DATA_FAILURE';

export const DISPLAY = {
    PENDING: 0,
    DELIVERED: -1
};


/*
 * action creators
 */

export function setOrderDisplay(display) {
    return {
        type: SET_ORDER_DISPLAY,
        display: display
    }
}

export function setNeedRefresh() {
    return {
        type: SET_NEED_REFRESH
    }
}

export function getDataPending() {
    return {
        type: FETCHING_ORDER_DATA_PENDING
    }
}

export function getDataSuccess(data, key) {
    return {
        type: FETCHING_ORDER_DATA_SUCCESS,
        list: data,
        key
    }
}

export function getDataFailure(error) {
    return {
        type: FETCHING_ORDER_DATA_FAILURE,
        error
    }
}

export function fetchPendingOrders() {
    return (dispatch, getState) => {
        const {user} = getState();
        let apiResponse;

        dispatch(getDataPending());

        return OrderAPI.getPendingOrders(user.token)
            .then(response => {
                apiResponse = response;
                return response.json();
            })
            .then(responseData => {
                if (apiResponse.status >= 200 && apiResponse.status < 300) {
                    dispatch(getDataSuccess(responseData, 'pending'));
                }
                else {
                    dispatch(getDataFailure(responseData.message));
                    ApplicationManager.handleError(dispatch, responseData.message);
                }
            })
    }
}

export function fetchDeliveredOrders() {
    return (dispatch, getState) => {
        const {user} = getState();
        let apiResponse;

        dispatch(getDataPending());

        return OrderAPI.getDeliveredOrders(user.token)
            .then(response => {
                apiResponse = response;
                return response.json();
            })
            .then(responseData => {
                if (apiResponse.status >= 200 && apiResponse.status < 300) {
                    dispatch(getDataSuccess(responseData, 'delivered'));
                }
                else {
                    dispatch(getDataFailure(responseData.message));
                    ApplicationManager.handleError(dispatch, responseData.message);
                }
            })
    }
}

