import React, {Component} from 'react';
import {ImageBackground, Text, View} from 'react-native';
import Foundation from 'react-native-vector-icons/Foundation';
// import I18n from '../../i18n/i18n';

import {Button} from 'react-native-elements';

import styles from './styles';


class Error extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    };

    signal() {
        this.props.navigation.goBack();
    };

    cancel() {
        this.props.navigation.goBack();
    };

    render() {
        let error;
        const errors = {
            DEFAULT_ERROR: "Un problème est survenu lors de votre commande. Si le problème persiste merci de nous contacter.",
            INVALID_TOKEN: "Votre session n'est plus valide. Vous devez vous identifier à nouveau.",
            EXPIRED_TOKEN: "Votre session n'est plus valide. Vous devez vous identifier à nouveau.",
            MISSING_TOKEN: "Votre session n'est plus valide. Vous devez vous identifier à nouveau.",
            BAD_CREDENTIAL: "Votre identifiant n’est pas valide."
        };

        if(this.props.navigation.state.params && this.props.navigation.state.params.error) {
            error = this.props.navigation.state.params.error;
            error = errors[error.slice(7)];
        }
        else {
            error = errors.DEFAULT_ERROR;
        }

        return (
            <View
                style={styles.container}>

                <ImageBackground style={styles.errorOverlay} source={require('../../resources/img/transparent-pattern.png')}>
                    <Foundation style={styles.icon}
                        size={70}
                        name="alert"
                        color={'#fff'} />
                    <Text style={styles.title}>Erreur</Text>
                    <Text style={styles.message}>{error}</Text>
                </ImageBackground>

                <View style={styles.footer}>
                    <Button
                        raised
                        buttonStyle={styles.button}
                        onPress={() => this.signal()}
                        title='Recommencer'/>

                    <Button
                        buttonStyle={styles.cancelButton}
                        textStyle={styles.cancelButtonText}
                        onPress={() => this.cancel()}
                        title='Retour'/>
                </View>

            </View>
        )
    }


}

export default Error;
