/*
* @flow
*
* A number button matrix
*/
import {ActivityIndicator, View} from 'react-native';
import React from 'react';

const Loading = (props) => (
    <View
        style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#F5FCFF88'
        }}
    >
        <ActivityIndicator
            animating={true}
            color='#67256a'
            size="large"
        />
    </View>

);

export default Loading;
