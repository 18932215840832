import React from 'react';
import {Platform, ImageBackground, Text, View} from 'react-native';
import Foundation from 'react-native-vector-icons/Foundation';
// import I18n from '../../i18n/i18n';

import {Button} from 'react-native-elements';

import styles from './styles';


class Success extends React.Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    };

    validate() {

        this.props.navigation.navigate("Index");
    };

    render() {
        let message;
        let title;

        if(this.props.navigation.state.params && this.props.navigation.state.params.message) {
            title = this.props.navigation.state.params.title;
            message = this.props.navigation.state.params.message;
        }
        else {
            title = "Succès";
            message = "Opération effectuée avec succès";
        }

        return (
            <View
                style={styles.successContainer}>

                <ImageBackground style={styles.overlay} source={require('../../resources/img/transparent-pattern.png')}>
                    <Foundation style={styles.icon}
                        size={70}
                        name="check"
                        color={'#fff'} />
                    <Text style={styles.title}>{ title }</Text>
                    <Text style={styles.message}>{ message }</Text>
                </ImageBackground>

                <View style={styles.footer}>
                    <Button
                        raised={(Platform.OS === 'ios')}
                        buttonStyle={styles.button}
                        onPress={() => this.validate()}
                        title="Retour à l'accueil"/>
                </View>

            </View>
        )
    }


}

/* Second method
* const mapDispatchToProps = {
        addToCart
};
* */

export default Success;
