import React from 'react';
import {FlatList, View, Text} from 'react-native';

// import I18n from '../../../i18n/i18n';
import styles from './styles';
import Modal from '../../ConfirmModal/ConfirmModal';

class StockItem extends React.PureComponent {
    _onPress = (stock) => {
        this.props.onPressItem(stock);
    };

    render() {
        const { stock, removeStockBtn } = this.props;

        return (
            <View style={[styles.item, stock.started ? styles.itemStarted : null]}>
                <View style={styles.itemContent}>
                    <View style={styles.itemCenter}>
                        <Text style={styles.itemTitle}>
                            <Text style={styles.colorPrimary}>{stock.name}</Text>
                        </Text>

                        <Text style={styles.itemData}>
                            <Text style={styles.italic}>{stock.complementaryName}</Text>
                        </Text>

                        <Text style={styles.itemData}>
                            <Text>Catégorie : </Text>
                            <Text style={styles.italic}>{stock.category}</Text>
                        </Text>

                    </View>
                    {!removeStockBtn ? 
                        <View style={styles.itemRight}>
                            <View style={styles.itemRightTop}>
                                <Text>Qantités : </Text>
                                <Text style={styles.italic}>{stock.currentQuantity} / {stock.initialQuantity} </Text>
                            </View>
                            <View style={styles.itemRightBottom}>
                                <Modal
                                    stock={stock}
                                    onConfirm={s => this._onPress(s)}
                                    title={"Êtes vous sûr ?"}
                                />
                            </View>
                        </View>
                    :
                        <View style={styles.itemRight}>
                            <View style={styles.itemRightTop}>
                                <Text>Qté initale : </Text>
                                <Text style={styles.italic}>{stock.initialQuantity}</Text>
                            </View>
                            <View style={styles.itemRightTop}>
                                <Text>Qté Restante : </Text>
                                <Text style={stock.currentQuantity > 10 ? styles.bold : [styles.bold, styles.red]}>
                                    {stock.currentQuantity}
                                </Text>
                            </View>
                        </View>
                    }
                </View>
            </View>
        )
    }
}

class StockList extends React.PureComponent {
    state = {};

    _keyExtractor = (item, index) => `list-item-${item.id}-${index}`;

    _onPressItem = stock => {
        this.props.onPress(stock);
    };

    _onRefresh = () => {
        this.props.onRefresh();
    };


    _renderItem = ({item}) => (
        <StockItem
            id={item.id}
            stock={item}
            onPressItem={this._onPressItem}
            removeStockBtn={this.props.removeStockBtn}
        />
    );


    render() {

        return (
            <FlatList
                refreshing={this.props.loading}
                onRefresh={this._onRefresh}
                contentContainerStyle={{flex: 0}}
                data={this.props.data}
                extraData={this.state}
                keyExtractor={this._keyExtractor}
                renderItem={this._renderItem}
            />
        );

    }
}

export default StockList;