/*
* @flow
*
* A number button matrix
*/
import { View, TouchableOpacity, StyleSheet, Linking } from 'react-native';

import {connect} from 'react-redux';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {NavigationActions, StackActions} from 'react-navigation';

import {ApplicationManager} from '../../../../core/managers';


import React from 'react';

const handleButton = (props) => {
    ApplicationManager.saveUserData(null).done();

    const resetAction = StackActions.reset({
        index: 0,
        actions: [
            NavigationActions.navigate({routeName: 'Authentication'}),
        ]
    });
    props.navigation.dispatch(resetAction);

};

let styles = StyleSheet.create({
    view: {
        paddingLeft: 10,
        paddingRight: 15,
    },
    icon: {
    }
});

const SignOutButton = (props) => {
    return (
        <View style={styles.view}>
            <TouchableOpacity onPress={() => { handleButton(props) }}>
                <MaterialCommunityIcons
                    name="exit-to-app"
                    size={24}
                    color="white"
                    style={styles.icon} />

            </TouchableOpacity>
        </View>
    );
};


function mapStateToProps (state) {
    return {
    }
}

function mapDispatchToProps (dispatch) {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SignOutButton);
