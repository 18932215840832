import {DeliveryAPI} from '../api';
import {ApplicationManager} from '../managers';

/*
 * action types
 */

export const UPDATE_CURRENT_LOCATION = 'UPDATE_LOCATION';

export const SET_DELIVERY_ACTION_PENDING = 'SET_DELIVERY_ACTION_PENDING';
export const SET_DELIVERY_ACTION_SUCCESS = 'SET_DELIVERY_ACTION_SUCCESS';
export const SET_DELIVERY_ACTION_FAILURE = 'SET_DELIVERY_ACTION_FAILURE';

export function updateLocation(date) {
    return {
        type: UPDATE_CURRENT_LOCATION,
        lastLocationUpdate: date
    }
}


export function setDeliveryActionPending() {
    return {
        type: SET_DELIVERY_ACTION_PENDING
    }
}

export function setDeliveryActionSuccess(data, key) {
    return {
        type: SET_DELIVERY_ACTION_SUCCESS,
    }
}

export function setDeliveryActionFailure(error) {
    return {
        type: SET_DELIVERY_ACTION_FAILURE,
        error
    }
}

export function startDelivery(orderID) {
    return (dispatch, getState) => {
        const {user} = getState();
        let apiResponse;

        dispatch(setDeliveryActionPending());

        return DeliveryAPI.startDelivery(user.token, orderID)
            .then(response => {
                apiResponse = response;
                return response.json();
            })
            .then(responseData => {
                if (apiResponse.status >= 200 && apiResponse.status < 300) {
                    dispatch(setDeliveryActionSuccess());
                }
                else {
                    dispatch(setDeliveryActionFailure());
                    ApplicationManager.handleError(dispatch, responseData.message);
                }
                return responseData;
            })
    }
}

export function finishPaidDelivery(orderID) {
    return (dispatch, getState) => {
        const {user} = getState();
        let apiResponse;

        dispatch(setDeliveryActionPending());

        return DeliveryAPI.finishPaidDelivery(user.token, orderID)
            .then(response => {
                apiResponse = response;
                return response;
            })
            .then(responseData => {
                dispatch(setDeliveryActionSuccess());
            })
            .catch(e => {
                console.log(e);
                dispatch(setDeliveryActionFailure());
                ApplicationManager.handleError(dispatch, responseData.message);
            })
    }
}

export function finishDeliveryWithCash(orderID) {
    return (dispatch, getState) => {
        const {user} = getState();
        let apiResponse;

        dispatch(setDeliveryActionPending());

        return DeliveryAPI.finishDeliveryWithCash(user.token, orderID)
            .then(response => {
                apiResponse = response;
                return response;
            })
            .then(responseData => {
                if (apiResponse.status >= 200 && apiResponse.status < 300) {
                    dispatch(setDeliveryActionSuccess());
                }
                else {
                    dispatch(setDeliveryActionFailure());
                    ApplicationManager.handleError(dispatch, responseData.message);
                }
            })
    }
}

export function finishDeliveryWithTerminal(orderID) {
    return (dispatch, getState) => {
        const {user} = getState();
        let apiResponse;

        dispatch(setDeliveryActionPending());

        return DeliveryAPI.finishDeliveryWithTerminal(user.token, orderID)
            .then(response => {
                apiResponse = response;
                return response;
            })
            .then(responseData => {
                if (apiResponse.status >= 200 && apiResponse.status < 300) {
                    dispatch(setDeliveryActionSuccess());
                }
                else {
                    dispatch(setDeliveryActionFailure());
                    ApplicationManager.handleError(dispatch, responseData.message);
                }
            })
    }
}

export function finishDeliveryNotPayed(orderID) {
    return (dispatch, getState) => {
        const {user} = getState();
        let apiResponse;

        dispatch(setDeliveryActionPending());

        return DeliveryAPI.finishDeliveryNotPayed(user.token, orderID)
            .then(response => {
                apiResponse = response;
                return response;
            })
            .then(responseData => {
                if (apiResponse.status >= 200 && apiResponse.status < 300) {
                    dispatch(setDeliveryActionSuccess());
                }
                else {
                    dispatch(setDeliveryActionFailure());
                    ApplicationManager.handleError(dispatch, responseData.message);
                }
            })
    }
}

export function updateCurrentLocation(latitude, longitude) {
    return (dispatch, getState) => {
        const {user} = getState();
        let apiResponse;

        return DeliveryAPI.updateCurrentLocation(user.token, latitude, longitude)
            .then(response => {
                apiResponse = response;
                return response.json();
            })
            .then(responseData => {
                if (apiResponse.status >= 200 && apiResponse.status < 300) {
                    dispatch(updateLocation(new Date()));
                }
                else {
                    ApplicationManager.handleError(dispatch, responseData.message);
                }
            })
    }
}