// @flow
import { Dimensions, StyleSheet } from 'react-native';

let {height, width} = Dimensions.get('window');

export default StyleSheet.create({
    successContainer: {
        flex: 1,
        // remove width and height to override fixed static size
        width: null,
        height: null,
        backgroundColor: '#32a583',
    },
    errorContainer: {
        flex: 1,
        // remove width and height to override fixed static size
        width: null,
        height: null,
        backgroundColor: '#a63a32',
    },
    overlay: {
        flex: 1,
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {

    },
    title: {
        margin: 10,
        color: '#fff',
        fontSize: 25,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    message: {
        color: '#fff',
        fontSize: 18,
        textAlign: 'center'
    },
    footer: {
        flex: -1,
        backgroundColor: 'white',
        borderColor: '#d6d7da',
        padding: 20,
        paddingTop: 10,
        flexDirection: 'column',
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,
    },
    infoTitle: {
        fontWeight: 'bold',
        marginTop: 10,
    },
    infoMain: {
        fontSize: 30,
        fontWeight: '200',
    },
    infoInformation: {

    },
    button: {
        margin: 5,
        borderRadius: 2,
        alignSelf: 'stretch',
        backgroundColor: '#67256a',
    },
    cancelButton: {
        backgroundColor: 'transparent',
        paddingBottom: 0,
        paddingTop: 9
    },
    cancelButtonText: {
        color: '#67256a',
        fontSize: 14
    },
    space: {
        height: 20
    }
});
