import React from 'react';
import {ImageBackground, View, Text} from 'react-native';

import StatList from '../../components/List/StatList';
import Loading from '../../components/Loading';

import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import {login} from '../../../core/actions/User';
import {fetchStats} from '../../../core/actions/Stat';
import {NavigationActions, StackActions} from 'react-navigation';

import {connect} from 'react-redux';

import styles from './styles';

import {ApplicationManager} from '../../../core/managers';


class Stat extends React.Component {

    constructor(props) {
        super(props);

        this.props.fetchStats();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.user.logged) {

            const resetAction = StackActions.reset({
                index: 0,
                actions: [
                    NavigationActions.navigate({routeName: 'Authentication' })
                ]
            });
            this.props.navigation.dispatch(resetAction);

            ApplicationManager.saveUserData(null).done();
        }

        if (nextProps.stat.needRefresh) {
            this.props.fetchStats();
        }
    }

    /* ======---------------------------
              Actions
    --------------------------------------------===== */
    onRefresh = () => {
        this.props.fetchStats();
    };

    /* ======---------------------------
               Renders
   --------------------------------------------===== */

    renderEmptyView() {
        return (
            <View style={styles.emptyView}>
                <MaterialCommunityIcons
                    name="cart-outline"
                    size={100}
                    color="#848484"
                    style={styles.emptyIcon}/>

                <Text style={styles.emptyText}>Les statistiques ne semblent pas disponibles</Text>
            </View>
        );
    }

    render() {
        const loading = this.props.stat.loading;
        const stats = this.props.stat.list;
        const { isAdmin } = this.props.user;

        return (
            <ImageBackground
                style={styles.container}
                source={require('../../resources/img/background-pattern.jpg')}>

                {loading ? 
                    <Loading/>
                :
                    <StatList
                        isAdmin={isAdmin}
                        loading={loading}
                        onRefresh={this.onRefresh}
                        data={stats}
                    />
                }
            </ImageBackground>
        )
    }
}


function mapStateToProps(state) {
    return {
        user: state.user,
        stat: state.stat,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        login: (email, password) => dispatch(login(email, password)),
        fetchStats: () => dispatch(fetchStats())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Stat);
