import {AsyncStorage} from 'react-native';
import {loginSuccess, loginFailure} from "../actions/User";

import {INVALID_TOKEN, MISSING_TOKEN, EXPIRED_TOKEN} from "../../src/config/config";


export async function isApplicationFirstLaunch(clear = false) {
    if (clear) {
        await AsyncStorage.removeItem('applicationFirstLaunch');
    }

    let response = await AsyncStorage.getItem('applicationFirstLaunch');
    let firstTime = await JSON.parse(response);

    if (firstTime === "1" || firstTime === null) {
        await AsyncStorage.setItem('applicationFirstLaunch', "0");

        return true;
    }

    return false;
}

export async function saveUserData(user) {
    if(user === null) {
        return await AsyncStorage.removeItem('user');
    }
    else {
        let userData = {
            username: user.username,
            token: user.token,
            isAdmin: user.isAdmin
        };
        return await AsyncStorage.setItem('user', JSON.stringify(userData));
    }
}


export async function getUserData() {

    let response = await AsyncStorage.getItem('user');
    return await JSON.parse(response);

}

export async function loadData(store) {

    return await this.getUserData().then((user) => {

        if(user !== null) {
            store.dispatch(loginSuccess(user.username, user.token, user.isAdmin));

            return true;
        }
        return false;
    });
}

export function handleError(dispatch, message) {

    if(message === INVALID_TOKEN || message === MISSING_TOKEN || message === EXPIRED_TOKEN) {
        dispatch(loginFailure(message));
    }
}
