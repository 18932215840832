// @flow
import { Dimensions, StyleSheet } from 'react-native';

let {height, width} = Dimensions.get('window');

export default StyleSheet.create({
    container: {
        flex: 1,
        width: null,
        height: null,
    },
    block: {
        flex: -1,
        marginTop: 12,
        marginLeft: 7,
        marginRight: 7,
        backgroundColor: 'white',
        borderColor: '#d6d7da',
        padding: 20,
        paddingTop: 10,
        flexDirection: 'column',
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,
    },
    paddingLeft: {
        paddingLeft: 20
    },
    infoTitle: {
        fontWeight: 'bold',
        marginTop: 10,
        color: '#0f0f0f'
    },
    mainTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        color: '#0f0f0f'
    },
    infoMain: {
        fontSize: 20,
        fontWeight: '300',
    },
    infoSubMain: {
        fontSize: 20,
        fontWeight: '300',
    },
    infoDescription: {
        color: '#0f0f0f'
    },
    inline: {
        flexDirection: 'row'
    },
    inlineLeft: {
        flex: 1,
    },
    inlineRight: {
        flex: -1,
        color: '#fda127',
    },
    recap: {
        backgroundColor: '#fff',
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 25,
        paddingRight: 25,
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
    },
    recapLine: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5,
        marginBottom: 5,
    },
    recapLineTitle: {
        flex: 1,
        fontSize: 15
    },
    recapLineData: {
        flex: -1,
        fontSize: 15
    },
    bold: {
        fontWeight: 'bold'
    },
    boldColor: {
        fontWeight: 'bold',
        color: '#fda127'
    },
    colorSecondary: {
        color: '#fda127',
    },
    horizontalSeparator: {
        borderBottomColor: '#e0e3e5',
        borderBottomWidth: 1,
        marginTop: 12,
        marginBottom: 12,
    },
    quantity: {
        color: "#32a583",
        fontWeight: "bold"
    },
    lineThrough: {
        textDecorationLine: 'line-through',
        color: '#fda127',
        fontSize: 11,
        marginRight: 5,
    },
    margin: {
        marginBottom: 20
    },
    primaryButton: {
        flex: 1,
        overflow: 'hidden',
        borderRadius: 2,
        backgroundColor: '#fda127',
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 11,
        height: 40,
        margin: 2,
    },
    secondaryButton: {
        flex: 1,
        overflow: 'hidden',
        borderRadius: 2,
        backgroundColor: '#32a583',
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 11,
        height: 40,
        margin: 2,
    },
    tertiaryButton: {
        flex: 1,
        overflow: 'hidden',
        borderRadius: 2,
        backgroundColor: '#67256a',
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 11,
        height: 40,
        margin: 2,
    },
    grayButton: {
        flex: 1,
        overflow: 'hidden',
        borderRadius: 2,
        backgroundColor: '#9c9da0',
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 11,
        height: 40,
        margin: 2,
    },
    footer: {
        flex: -1,
        backgroundColor: 'white',
        borderColor: '#d6d7da',
        padding: 10,
        flexDirection: 'row',
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowColor: 'black',
        shadowOffset: {height: 2, width: 0},
        elevation: 2,
    },
    buttonContainer: {
        margin: 0,
        padding: 0,
        width: width
    }
});
