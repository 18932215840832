import {
    UPDATE_CURRENT_LOCATION,
    SET_DELIVERY_ACTION_PENDING,
    SET_DELIVERY_ACTION_SUCCESS,
    SET_DELIVERY_ACTION_FAILURE,
} from '../actions/Delivery';

const initialState = {
    loading: false,
    error: false,
    success: false,
    lastLocationUpdate: null
};

export default function delivery(state = initialState, action) {
    switch (action.type) {

        case UPDATE_CURRENT_LOCATION:
            return state = updateObject(state, {
                lastLocationUpdate: action.date
            });

        case SET_DELIVERY_ACTION_PENDING:
            return state = updateObject(state, {
                loading: true,
                error: false,
                success: false,
            });

        case SET_DELIVERY_ACTION_SUCCESS:
            return state = updateObject(state, {
                loading: false,
                error: false,
                success: true,
            });

        case SET_DELIVERY_ACTION_FAILURE:
            return state = updateObject(state, {
                loading: false,
                error: true,
                success: false,
            });

        default:
            return state;
    }
};

function updateObject(myObj, value) {
    return Object.assign({}, myObj, value);
}
