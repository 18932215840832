import React from 'react';
import {ImageBackground, View, Text} from 'react-native';

import OrderList from '../../components/List/OrderList';
import Loading from '../../components/Loading';

import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import {login} from '../../../core/actions/User';
import {fetchPendingOrders, fetchDeliveredOrders, DISPLAY} from '../../../core/actions/Order';
import {updateCurrentLocation} from '../../../core/actions/Delivery';
import {NavigationActions, StackActions} from 'react-navigation';

import {connect} from 'react-redux';

import styles from './styles';

import {ApplicationManager} from '../../../core/managers';


class Order extends React.Component {

    constructor(props) {
        super(props);

        this.props.fetchPendingOrders();
        this.props.fetchDeliveredOrders();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.user.logged) {

            const resetAction = StackActions.reset({
                index: 0,
                actions: [
                    NavigationActions.navigate({routeName: 'Authentication' })
                ]
            });
            this.props.navigation.dispatch(resetAction);

            ApplicationManager.saveUserData(null).done();
        }

        if (nextProps.order.needRefresh) {
            this.props.fetchPendingOrders();
            this.props.fetchDeliveredOrders();
        }
    }

    componentDidMount() {
        this.trackLocation(this.props);
    };

    UNSAFE_componentWillUnmount() {
        navigator.geolocation.clearWatch(this.watchId);
    }

    /* ======---------------------------
              Actions
    --------------------------------------------===== */
    onRefresh = () => {
        this.props.fetchPendingOrders();
        this.props.fetchDeliveredOrders();
    };

    goToDelivery = (order, display) => {
        this.props.navigation.navigate("Delivery", {...{id: order.id, category: display, phoneNumber: order.customer.phoneNumber}});
    };

    trackLocation(props) {
        this.watchId = navigator.geolocation.watchPosition(
            (position) => {
                props.updateCurrentLocation(position.coords.latitude, position.coords.longitude);
            },
            (error) => this.setState({error: error.message}),
            {
                enableHighAccuracy: true,
                timeout: 20000,
                maximumAge: 60 * 1000,
                distanceFilter: 2000
            },
        );
    }


    /* ======---------------------------
               Renders
   --------------------------------------------===== */

    renderEmptyView() {
        return (
            <View style={styles.emptyView}>
                <MaterialCommunityIcons
                    name="cart-outline"
                    size={100}
                    color="#848484"
                    style={styles.emptyIcon}/>

                <Text style={styles.emptyText}>Vous n'avez encore aucune livraison pour le moment.</Text>
            </View>
        );
    }

    render() {
        const display = this.props.order.display;
        const loading = this.props.order.loading;
        const orders = display === DISPLAY.PENDING ? this.props.order.list.pending : this.props.order.list.delivered;
        // const hasProduct = orders !== null && orders.length > 0;

        return (
            <ImageBackground
                style={styles.container}
                source={require('../../resources/img/background-pattern.jpg')}>

                {display === DISPLAY.PENDING && <Text style={styles.headerPending}>Livraisons à effectuer : { this.props.user.username }</Text>}
                {display === DISPLAY.DELIVERED && <Text style={styles.headerDelivery}>Historique des commandes</Text>}

                <OrderList
                    loading={loading}
                    onRefresh={this.onRefresh}
                    data={orders}
                    display={display}
                    onPress={(order) => this.goToDelivery(order, display)}
                />

                {loading && <Loading/>}

            </ImageBackground>
        )
    }
}


function mapStateToProps(state) {
    return {
        user: state.user,
        order: state.order,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        login: (email, password) => dispatch(login(email, password)),
        fetchPendingOrders: () => dispatch(fetchPendingOrders()),
        fetchDeliveredOrders: () => dispatch(fetchDeliveredOrders()),
        updateCurrentLocation: (latitude, longitude) => dispatch(updateCurrentLocation(latitude, longitude)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Order);
