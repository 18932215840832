/**
 * @flow
 *
 * The root element
 */
import React from 'react';

import {AppNavigatorWrapper, AppAdminNavigatorWrapper} from './config/router';
import {Provider} from 'react-redux'
import configureStore from './configureStore'
import {ApplicationManager} from '../core/managers';

const store = configureStore();

class App extends React.Component {

  state = {
    initialized: false,
    initialRouteName: "Authentication",
    isAdmin: false
  };

  UNSAFE_componentWillMount() {
    ApplicationManager.getUserData().then(user => {
      if(user && user.isAdmin) {
        this.setState({
          isAdmin: true,
        });
      }
    });

    ApplicationManager.loadData(store).then(logged => {
      this.setState({
        initialized: true,
      });

      if(logged) {
        this.setState({
          initialRouteName: "Index",
        });
      }
    });
  }

  render() {
    const {isAdmin, initialRouteName} = this.state;

    return (
      <Provider store={store}>
        {isAdmin ? 
          <AppAdminNavigatorWrapper initialRouteName={initialRouteName} screenProps={{ name: '' }}/>
        :
          <AppNavigatorWrapper initialRouteName={initialRouteName} screenProps={{ name: '' }}/>
        }
      </Provider>
    );
  }
}


export default App;
